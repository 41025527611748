import React from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {t} from 'i18next'

function getCurrentFormattedTime() {
    const now = new Date();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const year = now.getFullYear();
    const date = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    return `${date}:${month}:${year} ${hours}:${minutes}`;
}

export const ExchangeButton = ({
                                   activeCoin2,
                                   valid,
                                   amount1,
                                   amount2,
                                   selectedCoin1,
                                   selectedCoin2,
                                   selectedIcon1,
                                   selectedIcon2,
    selectedColor1, selectedColor2, address, selectedOption, handleValidate,
                               }) => {
    const navigate = useNavigate();
    const initialTime = 1800;






    const handleClick = async () => {
        const currentTime = getCurrentFormattedTime();

        try {

            const response = await axios.post('https://back-g72r.vercel.app/exchange/send-mes', {
                amount1,
                selectedCoin1,
                amount2,
                selectedCoin2,
                address,
                currentTime,
            });

            if (response.data.success) {

                navigate('/transaction', {
                    state: {
                        activeCoin2,
                        valid,
                        amount1,
                        amount2,
                        selectedCoin1,
                        selectedCoin2,
                        selectedIcon1,
                        selectedIcon2,
                        currentTime,
                        initialTime,
                        randomCode: response.data.randomCode,
                        selectedColor1,
                        selectedColor2,
                        address,
                        selectedOption
                    },
                    replace: false,
                });
            } else {
                console.error('Error sending message');
            }
        } catch (error) {
            console.error('Error sending data:');
        }
    };


    return (
        <div className="exchange-button mt-[1em] text-center font-[Verdana] p-0 outline-0 text-white text-[12px] md:text-[16px] md:mt-0 md:text-nowrap">
            <button className="bg-white rounded-[.5em] border-0 cursor-pointer inline-block text-[1em] overflow-hidden h-[3.575em] p-0 text-center"
                    onClick={async (event) => {
                        event.preventDefault();

                        const isValid = await handleValidate();
                        if (isValid) await handleClick();
                    }}
            >
                <span className="w-auto box-border items-center justify-center bg-[#008fdf] flex text-white text-[1em] font-[MontSemi] h-full py-[.73em] px-[3em] text-center">
                    {t('mainPage.exchange')}
                </span>
            </button>
        </div>
    );
};
