import React from "react";
import Navbar from "../components/Navbar";
import Recent from "../components/Recent";
import background from "../img/new.svg";
import Exchange from "../components/Exchange";
import {Advantages} from "../components/Advantages";
import {Faq} from "../components/Faq";
import {Footer} from "../components/Footer";
import tgicon from "../img/telegram.png"



const MainPage = () => {

    const droidid = 'droidexchange'

    return (
        <div className={'text-[12px] min-h-screen bg-[#0c0d16] text-white flex flex-col md:text-[16px]'}>
            <a href={`https://t.me/${droidid}`}
               target="_blank"
               rel="noopener noreferrer" className={'right-5 bottom-5 fixed z-50 cursor-pointer rounded-full bg-blue-600 w-[50px] h-[50px] flex items-center pr-1 justify-center md:w-[60px] md:h-[60px] hover:bg-blue-900 transition'}>
                <img src={tgicon} alt={tgicon}/>
            </a>
            <div className="flex-[1_0_auto] min-h-screen bg-[#0c0d16]">
                <div className="box-border bg-[#151b23]  bg-cover text-white relative w-full min-h-screen pt-[55px]">
                    <Navbar/>
                    <div className="absolute w-full h-[120%] top-0 overflow-hidden z-0 bg-[#0c0d16]">
                        <img src={background} alt="background" className="w-full  object-cover absolute top-0 left-1/2 transform h-[67em] md:h-auto -translate-x-1/2 md:-translate-y-1/2"/>
                    </div>
                    <Exchange/>
                </div>
                <Advantages/>
                <Recent/>
                <Faq/>
            </div>
            <Footer/>
        </div>
    );
}
export default MainPage